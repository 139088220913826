$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

$(function(){
    $(".references-container").twentytwenty({
        before_label: 'Előtte',
        after_label: 'Utána'
    });
});

$('body').scrollspy({
    target: '#menu',
    offset: 80
});

$('.nav-link, .get-started').click(function() {
    var sectionTo = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(sectionTo).offset().top - 60
    }, 1000);
});

$(window).scroll(function() {
    if ($(document).scrollTop() > 100) {
        $('.navbar').addClass('affix');
    } else {
        $('.navbar').removeClass('affix');
    }
});

$('.navbar-nav>li>a').on('click', function(){
    $('.navbar-collapse').collapse('hide');
});

$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
        $('#back-to-top').fadeIn();
    } else {
        $('#back-to-top').fadeOut();
    }
});
$('#back-to-top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 400);
    return false;
});

var wow = new WOW({
    boxClass:     'wow',
    animateClass: 'animate__animated',
    offset:       0,
    mobile:       true,
    live:         true,
    callback:     function(box) {

    },
    scrollContainer: null
});
wow.init();


$("#send-email-btn").click(function(e) {
    e.preventDefault();

    var name = $("input[name='name']").val();
    var email = $("input[name='email']").val();
    var subject = $("input[name='subject']").val();
    var message = $("textarea[name='message']").val();
    var recaptcha = grecaptcha.getResponse();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
        type: 'POST',
        url: '/mail/send',
        data: {name:name, email:email, subject:subject, message:message, recaptcha:recaptcha},
        beforeSend: function() {
            $("#send-email-btn").attr("disabled", "disabled");
            $("#send-email-btn-text").html("Küldés folyamatban...");
            $(".print-error-msg").html("<ul></ul>");
            $(".print-error-msg").addClass("d-none");
        },
        success:function(response) {
            $("#send-email-btn").removeAttr("disabled");

            if($.isEmptyObject(response.error)) {
                printErrorMsg(response.success);
                $('#send-email-form').trigger("reset");
                $("#send-email-btn-text").html("Üzenet küldése");
                $(".print-error-msg").removeClass('alert-danger');
                $(".print-error-msg").addClass('alert-success');
            } else {
                printErrorMsg(response.error);
                $("#send-email-btn-text").html("Újrapróbálkozás");
                $(".print-error-msg").removeClass('alert-success');
                $(".print-error-msg").addClass('alert-danger');
            }
        },
        error: function(xhr, status, error) {
            var errorMessage = xhr.status + ': ' + xhr.statusText;
            alert(errorMessage);
            $("#send-email-btn").removeAttr("disabled");
            $("#send-email-btn-text").html("Újrapróbálkozás");
        }
    });

    grecaptcha.reset();
});

function printErrorMsg (msg) {
    $(".print-error-msg").find("ul").html('');
    $(".print-error-msg").removeClass('d-none');
    $(".print-error-msg").fadeIn(1000);
    $.each( msg, function( key, value ) {
        $(".print-error-msg").find("ul").append('<li>'+value+'</li>');
    });
}

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
